<template>
  <div class="container py-3">
    <div class="page-header">
      <h4>طلبات الأماكن الواردة</h4>
    </div>
    <div class="page-body">
      <tableComponent
        :columns="columns"
        :title="tableTitle"
        :mainAction="tableAction"
        :tHead="this.tHead"
        :keys="keys"
        :tData="tData"
        :allData="allData"
        :actions="actions"
        :isHeader="false"
        :isHeaderAction="true"
        excleFileName=" ملف طلبات الأماكن الواردة"
      />

      <div class="data-info-container">
        <Pagination
          :class="pagination - style"
          :data="laravelData"
          align="right"
          :show-disabled="true"
          :limit="2"
          @pagination-change-page="getIncomingAdvertisingRequests"
        >
          <template #prev-nav>
            <span>السابق</span>
          </template>
          <template #next-nav>
            <span>التالي</span>
          </template>
        </Pagination>

        <div v-if="isData">
          اعرض من {{ laravelData.from }} الي {{ laravelData.to }} من
          {{ laravelData.total }} نتيجه بحث
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from "@/components/globalComponents/tableComponent.vue";
import repository from "../../api/repository";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  components: { tableComponent, Pagination: LaravelVuePagination },

  async mounted() {
   this.$store.dispatch("setActivePage", "advertising");

    this.getIncomingAdvertisingRequestsAllData();
 
    this.getIncomingAdvertisingRequests();
  },
  data() {
    return {
      isData: false,
      allData: [],
      laravelData: {},
      tableTitle: "أحدث الطلبات الواردة",
      tableAction: {
        name: "عرض جميع الطلبات",
        path: "/incoming-advertising-requests",
      },
      tHead: ["اسم المكان", "اسم المستخدم", "المدينة", "التاريخ", "القسم", ""],
      keys: ["place_name", "user_name", "city", "date", "department"],

      columns: [
      {
          label: "رقم",
          field: "id",
        },
        {
          label: "اسم المكان",
          field: "place_name",
        },
        {
          label: "اسم المستخدم",
          field: "user_name",
        },
        {
          label: "المدينة",
          field: "city",
        },
        {
          label: "التاريخ",
          field: "date",
        },
        {
          label: "القسم",
          field: "department",
        },
      ],
      actions: [
        {
          url: "#",
          icon: "confirmIcon.svg",
          title: "قبول الطلب",
          flag: "alert",
          onClickHandler: async () => {
            console.log("confirm");

            let alertObj = {
              icon: "success.svg",
              message: "ok",
            };

            // await this.$store.dispatch("setAlertFlag", true);

            return alertObj;
            // await this.$store.dispatch("setAlertObject", alertObj);
          },
        },
        {
          url: "#",
          icon: "viewIcon.svg",
          title: " عرض الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            this.$router.push({path: `/incoming-advertising-requests/${id}`})
           console.log({id});
          },
        },
        {
          url: "#",
          icon: "trashIcon.svg",
          title: "حذف الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            let confirmObj = {
              icon: {
                name: "fa fa-trash",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#d54834",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              confirmMessage: "هل ترغب بالفعل في حذف طلب اضافة المكان؟",
              onAcceptConfirm: async () => {
                console.log("ok", id);
                await this.$store.dispatch("setConfirmFlag", false);
              },
              onCancleConfirm: async () => {
                console.log("no");
                await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            // await this.$store.dispatch("setConfirmObject", confirmObj);
            return confirmObj;
          },
        },
      ],
      // tData: [
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      //   {
      //     placeName: "عنوان",
      //     userName: "اسلام",
      //     city: "الاسكندريه",
      //     date: "2022-08-28",
      //     department: "أنشطة طلابيه",
      //   },
      // ],

      tData: [ 
      ],
    };
  },
  methods: {
    async getIncomingAdvertisingRequests(page = 1) {

      const res = await repository.index("incoming-advertisement-requests",true, page);
      if(res.status === 200){
        
        this.laravelData = res.data;
        this.tData = res.data.data

        if(this.tData.length > 0)
        this.isData = true;
      }
    
    },

    async getIncomingAdvertisingRequestsAllData(pagination = false , page = 1) {
      const res = await repository.index("incoming-advertisement-requests",pagination, page);
      this.allData = res.data
    },
  },
};
</script>

<style scoped>


.data-info-container {
  display: flex;
  justify-content: space-between;
  direction: ltr;
}
</style>
