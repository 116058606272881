<template>
  <commonComponent :regionData="this.regionData" :info="this.info" />
</template>

<script>

import commonComponent from "./components/commonComponent.vue";
export default {
  components: { commonComponent },
  async mounted() {
    this.info = {
      title: "اضافه منطقة جديدة",
      submitUrl: "regions",
      action: "create",
      actionBtn: {
        title: "اضافه منطقة",
        bgColor: "#1a3f77"
      },
    };
  },
  data() {
    return {
      regionData: {
        name: "",
      },

      info: {
        title: "",
        submitUrl: "",
        action: "",
        actionBtn: {
        title: "",
        bgColor: ""
      },
      },
    };
  },
};
</script>

<style scoped>
.page-container {
  height: 75vh;
}
.page-body {
  background-color: #fff;
  margin-top: 10px;
  height: 100%;
}

.page-footer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.data-row-container {
  height: fit-content;
}

.send-btn {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: #1a3f77;
}

.page-header .create-paid-ads {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #1a3f77;
}
/* select */
.custom-select {
  width: 90%;
  height: fit-content;
  padding: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  appearance: none;
  border-width: 2px;
  border-color: transparent;
  box-sizing: border-box;
  padding-right: 10px;
  height: 2rem;
  flex-grow: 1;
}
select:focus {
  outline: none;
  border-color: none;
}
label {
  position: relative;
  width: 100%;
  /* min-width: 10em;
  max-width: 18em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 128, 0, 0.5);
}
label::after {
  /* content: '\25BE'; */
  content: url("../../assets/svg/expand_more_black_icon.svg");
  background-color: var(--focused-border-color);
  color: #fff;
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 290px;
  padding-right: 25px;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.custom-text-input {
  width: 90%;
  padding: 0 10px;
  height: 54px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-text-input:focus {
  outline: none;
}

::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

/* select */
/* upload File */
.file-wrapper {
  text-align: center;
  width: 95%;
  vertical-align: middle;
  /* display: table-cell; */
  position: relative;
  overflow: hidden;
  background: #fff; /* and other things to make it pretty */
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 165px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.file-wrapper input {
  position: absolute;
  top: 0;
  right: 0; /* not left, because only the right part of the input seems to
                 be clickable in some browser I can't remember */
  cursor: pointer;
  opacity: 0;
  filter: alpha(
    opacity=0
  ); /* and all the other old opacity stuff you
                                 want to support */
  font-size: 300px; /* wtf, but apparently the most reliable way to make
                         a large part of the input clickable in most browsers */
  height: 200px;
}

/* upload File */

.files-info-container {
  margin-top: 10px;
  list-style: none;
}

.files-container {
  height: 180px;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #dc3545;
  color: #fff;
  font-size: 10px;
}
</style>
