<template>

<header class="navbar navbar-dark sticky-top  flex-md-nowrap p-0 shadow">
  
  <div>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="عرض/إخفاء لوحة التنقل">
    <span class="navbar-toggler-icon"></span>
  </button>
  </div>
  

  <ul class="nav nav-pills">
              
               <li class="p-2 notification nav-item dropdown">

                <!-- <button type="" class="btn btn-primary position-relative"> -->
 <a class="select-icon nav-link dropdown-toggle  position-relative" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                  <font-awesome-icon icon="fa-solid fa-bell" />
                  <span class="notification-num position-absolute translate-middle badge rounded-pill bg-danger">
    99+
  </span>
                </a>
  
<!-- </button> -->
                <!-- <a class="select-icon nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                  <font-awesome-icon icon="fa-solid fa-bell" />
                </a> -->
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#one">واحد</a></li>
                
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a class="user-info-container nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                  <span class="user-icon">
                    <font-awesome-icon icon="fa-solid fa-user" />
                  </span>
                  <div class="d-flex flex-column">
                   <span>
                    اسم المستخدم
                    </span>
                    <span>
                    {{user?.name}}

                    </span>
                  
                  </div>
                  </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" @click="logoutHandler" href="#one">تسجيل الخروج</a></li>
                  <!-- <li><a class="dropdown-item" href="#two">اثنان</a></li> -->
                
                </ul>
              </li>
  </ul>
</header>


<confirmComponent v-if="this.confirmFlag" :confirmObj="this.data" @close-emit="this.confirmFlag = false" />

</template>

<script>
  import { mapGetters } from "vuex";
  import confirmComponent from '@/components/globalComponents/confirmComponent.vue';

export default {
  components: {confirmComponent},

  data(){
    return{
      data: null,
      confirmFlag: false
    }
  },
  methods:{
   async logoutHandler(){
      let confirmObj = {
              icon: {
               
                name: "fa fa-arrow-right-from-bracket",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#000",
                  bgColor: "#FFF",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "",
              confirmMessage: "هل  بالفعل تريد الخروج من لوحة التحكم؟",
              onAcceptConfirm: async () => {
                console.log("ok");
                // await this.$store.dispatch("setConfirmFlag", false);

                await this.$store.dispatch("logout");
                await this.$router.push({name: 'login'});
              },
              onCancleConfirm: async () => {
                console.log("no");
                // await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            this.confirmFlag = true;
            // await this.$store.dispatch("setConfirmObject", confirmObj);

            console.log("test => ",this.dataFormate(confirmObj));
            this.data = this.dataFormate(confirmObj);
    }, 
    dataFormate(data){
return new Promise(function(resolve, reject) {
                                      resolve(data);
                                })
    }
  },
  computed:{
    ...mapGetters({
      user: 'user'
    })

  }
};
</script>

<style scoped>

.notification-num{
  right: 0;
  top:5px
}
.user-info-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  color:#FFF
}

.user-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #FFF;
  border-radius: 50%;
  color: #DDD;
  background-color: #FFF;
}

.notification .select-icon{
  color: #FFF;
  font-size: 18px;
}

.notification  ::after{
  display: none;
}

header.navbar{
  height: 65px;
  background-color: var(--appMainColor);
  z-index: 0;
}

.navbar-dark .navbar-toggler{
  border-color: #FFF;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: 1rem;
  right: 1rem;
  /* left: 1rem; */
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
  height: 65px;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.notification{
  border-left: 1px solid #FFF;
}

</style>

