<template>
  <div class="alert-component" v-if="this.data">
    <div class="d-flex flex-column align-items-center gap-3 alert-container">
      
      <div class="alert-icon">
        <img :src="require(`../../assets/svg/${this.data?.icon}`)" alt="">
      </div>

      <div class="alert-body p-0">
        {{this.data?.message}}
      </div>


      <div class="action-container" v-if="this.data?.isAction">
        <router-link :to="this.data.actionUrl" ref="input" @click="onClose">
              {{this.data?.actionTitle}}
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  name: 'alert-component',
  props:['alertObj'],

  data() {
    return{
      data: null
    }
  },

  methods:{
    async onClose(){
      this.$emit('close-emit')
    }
  },
   mounted(){

    this.alertObj.then(res => {
      this.data = res 
      if(!this.data?.isAction){
      setTimeout(this.onClose, 2000)
    }
    });

  },


// computed: {
//     ...mapGetters({
//       alertObj: 'alertObject',
//     })
//   }

}
</script>

<style scoped>

  .action-container{
    background-color: var(--appMainColor);
    text-align: center;
    padding: 10px 40px;
    border-radius: 4px;
  }

  .action-container a{
    color:#FFF;
    text-decoration: none;
  }
.alert-component{
  position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000000e8;
    color: #FFF;
    z-index: 1000;
    display: flex;
    justify-content: center; 
}

.alert-container{
  width: 40%;
    height: fit-content;
    background-color: #FFF;
    color: #000;
    padding: 15px;
}

.alert-body{
  text-align: center;
}
.alert-icon{
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #DDD;
}
</style>