<template>
  <div class="d-flex login-container">
    <div class="right-side gap-4 w-75">
      <div>
        <img src="../../assets/svg/right_side_logo.svg" alt="" srcset="" />
      </div>

      <div class="d-flex flex-column w-50">
        <p>مرحبا بعودتك! يرجي تسجيل الدخول الي حسابك</p>
      </div>

      <p v-if="error" style="color: red">{{ error }}</p>
     
      <div class="d-flex flex-column gap-3 w-50">
        <form>
          <div class="form-floating mb-3">
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="name@example.com"
              v-model="user.email"
            />
            <label for="email">البريد الالكتروني</label>
          </div>
        </form>
      </div>

      <div class="w-50">
        <button class="w-100 login-btn" @click="forgetPassword">اعادة تعيين كلمه المرور</button>
      </div>

    </div>
    <div class="left-side w-50"></div>
  </div>

  <alertComponent v-if="this.alertFlag"  :alertObj="this.data" @close-emit="this.alertFlag = false"/>

</template>

<script>
import repository from '@/api/repository';
import alertComponent from '@/components/globalComponents/alertComponent.vue';


export default {
  name: "ForgetPassword",
  components: {alertComponent},

  data() {
    return {  
      data: null,
      alertFlag:false,
      user: {
        email: null,
      },
      error: null,
    };
  },

  methods: {
    async forgetPassword() {
      this.error = null;

      let res = null;
      try {
        this.loading = true;
        res =  await repository.forgetPassword(this.user);
        let alertObj = {
              icon:'success.svg',
              message: 'تم ارسال الرابط بنجاح يمكنك مراجعه البريد الالكترونى لتغيير كلمه المرور',
              isAction: true,
              actionUrl:'/login',
              actionTitle: 'العودة لتسجيل الدخول'
            }


            if(res !== null){
              this.alertFlag = true
            }
            // await this.$store.dispatch("setAlertFlag", true);

            // await this.$store.dispatch("setAlertObject", alertObj);
            this.data = this.dataFormate(alertObj);

      } catch (error) {
        this.error = error.response.data.message;
        console.log({ error });
      } finally {
        this.loading = false;
        
      }
    },
    onShowPass() {
      this.isHidden ^= true;

      this.passwordShowHideTag = this.isHidden ? "اظهر" : "اخفي";
    },
    dataFormate(data){
return new Promise(function(resolve, reject) {
                                      resolve(data);
                                })
    }
  },
};
</script>

<style scoped>
.login-container {
  height: 100vh;
}
.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 40px;
}
.left-side {
  background-image: url("../../assets/svg/login_left_side.svg");
  background-position: center;
  background-size: cover;
}

.login-btn {
  padding: 15px;
  border: none;
  background-color: var(--appMainColor);
  color: #fff;
  border-radius: 4px;
}

.show-pass-btn {
  position: absolute;
  top: 13px;
  left: 0;
  cursor: pointer;
}

#email,
#email:focus,
#password,
#password:focus {
  border: none;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 0 0;
}

@media screen and (max-width: 600px) {
  .left-side {
    display: none;
  }
  .right-side {
    width: 100% !important;
  }
}
</style>
