<template>
  <div v-if="this.isHeader" class="table-header">
    <h4>{{ title }}</h4>
    <span v-if="mainAction" @click="tableActionHandler" class="table-acton">{{
      mainAction.name
    }}</span>
  </div>
  <div class="d-flex gap-2 my-3 action-container">
    <div class="d-flex gap-3 search-input-container">
      <img
        :src="require(`../../assets/svg/search.svg`)"
        alt="search"
        srcset=""
        class="img-search"
        @click="searchHandler"
      />
      <input
        type="text"
        v-model="searchInput"
        :name="this.headerActions.search.name"
        :placeholder="this.headerActions.search.placeholder"
        id=""
      />
    </div>
    <div
      v-if="this.isHeaderAction && this.tData.length > 0"
      class="d-flex justify-content-end"
    >
      <vue-excel-xlsx
        :data="this.allData"
        :columns="this.columns"
        :file-name="this.excleFileName"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        class="download-btn-style"
      >
        <span @click.prevent="downloadHandler($event)" class="test">
          تحميل الملف
          <img src="../../assets/svg/microsoft-excel.svg" />
        </span>
      </vue-excel-xlsx>
    </div>
  </div>
  <div class="no-data-container" v-if="this.tData.length === 0">
    <p class="text-center">لا يوجد بيانات</p>
  </div>
  <div v-else>
    <div class="table-responsive table-container">
      <table class="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col" class="data-no">#</th>
            <th scope="col" v-for="head in this.tHead">{{ head }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, idx) in this.tData">
            <!-- <td>{{ idx + 1 }}</td> -->
            <td>{{ data["id"] }}</td>
            <td v-for="key in this.keys">{{ data[key] }}</td>
            <td>
              <span v-for="action in actions">
                <router-link
                  :to="action.url"
                  :title="action.title"
                  @click="
                    onClickHandler($event, action, action.flag, data['id'])
                  "
                >
                  <img
                    :src="require(`../../assets/${action.icon}`)"
                    :alt="action.title"
                  />
                </router-link>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <alertComponent
    v-if="this.alertFlag"
    :alertObj="this.obj"
    @close-emit="this.alertFlag = false"
  />
  <confirmComponent
    v-if="this.confirmFlag"
    :confirmObj="this.obj"
    @close-emit="this.confirmFlag = false"
  />
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";
import alertComponent from "@/components/globalComponents/alertComponent.vue";
import confirmComponent from "@/components/globalComponents/confirmComponent.vue";
import LaravelVuePagination from "laravel-vue-pagination";

import { mapGetters } from "vuex";

export default {
  name: "table-component",

  components: {
    Pagination: LaravelVuePagination,
  },
  props: [
    "title",
    "mainAction",
    "tHead",
    "tData",
    "keys",
    "actions",
    "columns",
    "isHeader",
    "isHeaderAction",
    "headerActions",
    "allData",
    "excleFileName",
  ],
  components: { alertComponent, confirmComponent },

  mounted() {
    console.log("--", this.laravelData);
  },
  data() {
    return {
      searchInput: "",
      confirmFlag: false,
      alertFlag: false,
      json: {
        data: this.tData,
        head: this.tHead,

        fileName: "download.xls",
        obj: null,
      },
    };
  },
  // computed: {
  // ...mapGetters({
  // confirmFlag: 'confirmFlag',
  // alertFlag: 'alertFlag',
  // alertObject: 'alertObject',
  // isLoading: 'isLoading',

  // })
  // },
  methods: {
    tableActionHandler() {
      console.log(this.mainAction.path);
      this.$router.push({ path: this.mainAction.path });
    },
    downloadHandler(e) {
      e.preventDefault();
      this.tData = [];
      console.log("downloadHandler");
    },
    onClickHandler(e, action, flag, id) {
      let ret = null;
      console.log({ action });
      if (flag === "alert") {
        console.log({ flag });
        this.alertFlag = true;
      } else {
        this.confirmFlag = true;
      }

      ret = action?.onClickHandler(id);
      this.obj = ret;
      console.log({ ret });
    },

    searchHandler() {
      console.log(
        "searchHandler => ",
        this.headerActions.search.searchCB(this.searchInput)
      );
      console.log("Data => ", this.searchInput);
    },
  },
};
</script>

<style scoped>
.table-header {
  display: flex;
  justify-content: space-between;
  /* align-content: center; */
  align-items: center;
}

.table-acton {
  font-weight: 900;
  text-decoration: underline;
  cursor: pointer;
}
.data-no {
  width: 80px;
}
th {
  /* width: 6%; */
  background-color: var(--appMainColor);
  color: #fff;
  margin-left: 11px;
  border-left: 3px solid var(--appBgColor);
  border-radius: 10px;
  padding: 8px;
  justify-content: center;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #fff;
}

td {
  margin-left: 11px;
  border-left: 3px solid var(--appBgColor);
}

tbody {
  text-align: center;
}

.table-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.download-btn-style {
  border: none;
  background-color: #20509a;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
}

.no-data-container {
  height: 77vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-container p {
  font-size: 24px;
  font-weight: 500;
}

.search-input-container {
  direction: rtl;
  width: fit-content;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 4px;
}

.search-input-container input[type="text"],
.search-input-container input[type="text"]:focus,
.search-input-container input[type="text"]:focus-visible,
.search-input-container input[type="text"]:hover {
  border: none;
  outline: 0;
}

.search-input-container input[type="text"] {
  width: 180px;
}

.action-container {
  direction: ltr;
}

.img-search {
  cursor: pointer;
}
</style>
