import {
  createRouter,
  createWebHistory
} from 'vue-router'
import WrapperView from '../views/WrapperView.vue'

import Home from '../views/home/index.vue'


//advertising 
import AdvertisingIndex from '../views/advertising/index.vue'
import AdvertisingShow from '../views/advertising/show.vue'


//Paid ads
import PaidAdsIndex from '../views/paidAds/index.vue';
import PaidAdsCreate from '../views/paidAds/create.vue';
import PaidAdsUpdate from '../views/paidAds/update.vue';


//users
import UsersIndex from '../views/users/index.vue'

//Regions
import RegionsIndex from '../views/regions/index.vue'
import RegionsCreate from '../views/regions/create.vue'
import RegionsUpdate from '../views/regions/update.vue'



//departments
import DepartmentsIndex from '../views/departments/index.vue'
import DepartmentsCreate from '../views/departments/create.vue'
import DepartmentsUpdate from '../views/departments/update.vue'


//notifications
import NotificationsIndex from '../views/notifications/index.vue'



//contact requests
import ContactRequestsIndex from '../views/contactRequests/index.vue'


//reports
import ReportsIndex from '../views/reports/index.vue'


import middleware from './middleware';

import Login from "@/views/auth/Login";
import ForgetPassword from "@/views/auth/ForgetPassword";

import Upload from "@/views/uploadImage/UploadImageView.vue";
import DragAndDrop from "@/views/uploadImage/DragAndDrop.vue";
import Index from "@/views/posts/Index";
const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: middleware.guest,
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword,
    beforeEnter: middleware.guest,
  },
  // {
  //   path: '/upload',
  //   name: 'upload',
  //   component: Upload,
  // },

  // {
  //   path: '/drag-drop',
  //   name: 'DragAndDrop',
  //   component: DragAndDrop,
  // },
  {
    path: '/',
    name: 'home',
    component: WrapperView,
    beforeEnter: middleware.user,
    children: 
    [
      {
        path: '',
        name: 'home',
        component: Home,
        beforeEnter: middleware.user,
      },
      {
        path: '/incoming-advertising-requests',
        name: 'advertising',
        component: AdvertisingIndex,
        beforeEnter: middleware.user,
      },

      {
        path: '/incoming-advertising-requests/:id',
        name: 'advertisingShow',
        component: AdvertisingShow,
        beforeEnter: middleware.user,
      },
      {
        path: '/paid-ads',
        name: 'paid-ads',
        component: PaidAdsIndex,
      },
      {
        path: '/paid-ads/create',
        name: 'create-paid-ads',
        component: PaidAdsCreate,
      },
      {
        path: '/paid-ads/update/:id',
        name: 'update-paid-ads',
        component: PaidAdsUpdate,
      },
      {
        path: '/users',
        name: 'users-index',
        component: UsersIndex,
      },
      {
        path: '/regions',
        name: 'regions-index',
        component: RegionsIndex,
      },
      {
        path: '/regions/create',
        name: 'regions-create',
        component: RegionsCreate,
      },
      {
        path: '/regions/update/:id',
        name: 'regions-update',
        component: RegionsUpdate,
      },
      {
        path: '/departments',
        name: 'departments-index',
        component: DepartmentsIndex,
      }, 
      {
        path: '/departments/create',
        name: 'departments-create',
        component: DepartmentsCreate,
      },
      {
        path: '/departments/update/:id',
        name: 'departments-update',
        component: DepartmentsUpdate,
      },
      {
        path: '/contact-requests',
        name: 'requests-index',
        component: ContactRequestsIndex,
      }, 
      {
        path: '/notifications',
        name: 'notifications-index',
        component: NotificationsIndex,
      }, 
      {
        path: '/reports',
        name: 'reports-index',
        component: ReportsIndex,
      },


      //for testing
      {
        path: '/about',
        name: 'about',
        component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue'),
      },
      {
        path: '/posts',
        name: 'posts',
        component: Index,
        beforeEnter: middleware.user,
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router