<template>

<section class="loading">

<span class="loadWords">جاري التحميل ...</span>
<span class="loading__anim"></span>
</section>
</template>

<script>
export default {
  name: 'loading-component',
  data() {
    return{

    }
  },

  mounted(){
  },
  computed: {
    isLoading(){
      return this.$store.state.global.isLoading;
    },
}

}
</script>

<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading {
  z-index: 1000000;
    background-color: #000000;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  width: 100%;
  height:100%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.loadWords {
  font-weight: normal;
  font-size: 0.9rem;
  color: rgba(189, 189, 189, 1);
  margin: 0.6rem 0 2rem 0;
  display: block;
}

.loading__anim {
  width: 35px;
  height: 35px;
  border: 5px solid rgba(189, 189, 189, 0.25);
  border-left-color: rgba(3, 155, 229, 1);
  border-top-color: rgba(3, 155, 229, 1);
  border-radius: 50%;
  display: inline-block;
  animation: rotate 600ms infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}


</style>