import axios from "axios";
import store from "../store/index";

let instance = axios.create({
    withCredentials: true,
});

instance.interceptors.request.use(async request => {
    request.headers.common['Accept']       = 'application/json';
    request.headers.common['Content-Type'] = 'application/json';
    request.headers.common['Authorization'] = `Bearer ${sessionStorage.user ? JSON.parse(sessionStorage.getItem('user'))['token'] : null}`

    console.log('loadding start');
    store.dispatch("setIsLoading", true);
    return request;
});

instance.interceptors.response.use(
async response => { 
    console.log('loadding end');
    store.dispatch("setIsLoading", false);
    return response; 

},
error => {
    store.dispatch("setIsLoading", false);
        if (error.response.status === 401) {
            sessionStorage.removeItem('user');
            window.location.reload();
        }

    // console.log('loadding end');

        return Promise.reject(error);
    }
);

export default instance;
