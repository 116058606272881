import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import 'material-icons/iconfont/material-icons.css';
// import 'material-icons/iconfont/filled.css';


/* import specific icons */
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import {  faLocationPin } from '@fortawesome/free-solid-svg-icons'
import {  faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'


import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'



import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faChartSimple } from '@fortawesome/free-solid-svg-icons'

// import excel from 'vue-excel-export'
// import { jsontoexcel } from "vue-table-to-excel"
import VueExcelXlsx from "vue-excel-xlsx";
/* add icons to the library */
library.add(faUser, faBell, faHouse, faChartSimple, faTimes, faUsers, faLocationPin, faTrash, faPen, faArrowRightFromBracket)

createApp(App).use(store).use(router).use(VueExcelXlsx).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
