<template>
  <div class="container py-3">
    <div class="page-header d-flex justify-content-between">
      <h4>الاعلانات المدفوعه</h4>
      <router-link class="create-paid-ads" to="/paid-ads/create"
        >انشاء وحدة اعلانيه جديدة <img src="../../assets/svg/plus_icon.svg" />
      </router-link>
    </div>
    <div class="page-body">
      <tableComponent
        :columns="columns"
        :title="tableTitle"
        :mainAction="tableAction"
        :tHead="this.tHead"
        :keys="keys"
        :tData="tData"
        :allData="allData"
        :actions="actions"
        :isHeader="false"
        :isHeaderAction="true"
        :headerActions="headerActions"
        excleFileName=" ملف الاعلانات المدفوعه"
      />

      <div class="data-info-container">
        <Pagination
          :class="pagination - style"
          :data="laravelData"
          align="right"
          :show-disabled="true"
          :limit="2"
          @pagination-change-page="getPaidAds"
        >
          <template #prev-nav>
            <span>السابق</span>
          </template>
          <template #next-nav>
            <span>التالي</span>
          </template>
        </Pagination>

        <paginationInfoComponent v-if="isData" :data="infoData" />

      </div>
    </div>
  </div>

  <alertComponent
    v-if="this.alertFlag"
    @close-emit="this.alertFlag = false"
    :alertObj="this.getAlertObj()"
  />
</template>

<script>
import moment from "moment";
import tableComponent from "@/components/globalComponents/tableComponent.vue";
import repository from "../../api/repository";

import LaravelVuePagination from "laravel-vue-pagination";

import alertComponent from "@/components/globalComponents/alertComponent.vue";
import paginationInfoComponent from "../../components/globalComponents/paginationInfoComponent.vue";

export default {
  components: {
    tableComponent,
    Pagination: LaravelVuePagination,
    alertComponent,
    paginationInfoComponent,
  },

  async mounted() {
    this.$store.dispatch("setActivePage", "paid-ads");

    this.getPaidAdsAllData();

    this.getPaidAds();
  },
  data() {
    return {
      alertFlag: false,
      infoData: {},
      isData: false,
      allData: [],
      laravelData: {},
      tableTitle: "أحدث الطلبات الواردة",
      tableAction: {
        name: "عرض جميع الطلبات",
        path: "/incoming-advertising-requests",
      },
      tHead: [
        "الوحدات الاعلانيه",
        "ابعاد الوحدةالاعلانيه",
        "مكان الوحدة الاعلانيه",
        "مشاهدات الاعلان",
        "تاريخ الاعلان",
        "",
      ],
      keys: ["name", "dimensions", "department", "views_number", "created_at"],

      columns: [
        {
          label: "رقم",
          field: "id",
        },
        {
          label: "الوحدات الاعلانيه",
          field: "name",
        },
        {
          label: "ابعاد الوحدةالاعلانيه",
          field: "dimensions",
        },
        {
          label: "مكان الوحدةالاعلانيه",
          field: "department",
        },
        {
          label: "مشاهدات الاعلان",
          field: "views_number",
        },
        {
          label: "تاريخ الاعلان",
          field: "created_at",
        },
      ],
      actions: [
        {
          url: "#",
          icon: "svg/trashIcon.svg",
          title: "حذف الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            let confirmObj = {
              icon: {
                name: "fa fa-trash",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#d54834",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              confirmMessage: "هل ترغب بالفعل في حذف الاعلان ؟",
              onAcceptConfirm: async () => {
                try {
                  await repository.destroy("paid-advertisements", id);
                  await this.$store.dispatch("setConfirmFlag", false);
                  this.deleteFn("success");
                } catch {
                  this.deleteFn("fail");
                }
              },
              onCancleConfirm: async () => {
                console.log("no");
                await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            // await this.$store.dispatch("setConfirmObject", confirmObj);
            return confirmObj;
          },
        },
        {
          url: "#",
          icon: "svg/editIcon.svg",
          title: "تعديل الاعلان",
          flag: "confirm",
          onClickHandler: async (id) => {
            this.$router.push({ path: `/paid-ads/update/${id}` });
            console.log({ id });
          },
        },
      ],
      tData: [],

      headerActions: {
        search: {
          name: "search",
          url: "/paid-advertisements",
          placeholder: "ابحث في الاعلانات المدفوعه",
          searchCB: async (search) => {
            const res = await repository.index(
              "paid-advertisements",
              true,
              1,
              search
            );
            console.log({ res });

            if (res.status === 200) {
              console.log({ res });
              this.laravelData = res.data;
              this.tData = res.data.data;
      
              this.infoData = {
                total: this.laravelData.total,
                from: this.laravelData.from,
                to: this.laravelData.to,
              };

              if (this.infoData.total > 8) this.isData = true;
            }
            await this.formatData(this.tData);
          },
        },
      },
    };
  },
  methods: {
    async deleteFn(status) {
      await this.getPaidAds();
      await this.getPaidAdsAllData();
      if (status === "success") {
        this.alertObj = {
          icon: "success.svg",
          message: "تم حذف الاعلان بنجاح",
        };
      } else {
        this.alertObj = {
          icon: "error.svg",
          message: "خطا",
        };
      }

      this.alertFlag = true;
    },
    getAlertObj() {
      return new Promise((resolve, reject) => {
        resolve(this.alertObj);
      });
    },
    async getPaidAds(page = 1) {
      const res = await repository.index("paid-advertisements", true, page);
      console.log({ res });

      if (res.status === 200) {
        console.log({ res });
        this.laravelData = res.data;
        this.tData = res.data.data;
       
        this.infoData = {
          total: this.laravelData.total,
          from: this.laravelData.from,
          to: this.laravelData.to,
        };

        if (this.infoData.total > 8) this.isData = true;
      }
      await this.formatData(this.tData);
    },

    async searchCB(search) {
      const res = await repository.index(
        "paid-advertisements",
        true,
        page,
        search
      );
      console.log({ res });

      if (res.status === 200) {
        console.log({ res });
        this.laravelData = res.data;
        this.tData = res.data.data;

        this.infoData = {
          total: this.laravelData.total,
          from: this.laravelData.from,
          to: this.laravelData.to,
        };

        if (this.infoData.total > 8) this.isData = true;
      }
      await this.formatData(this.tData);
    },

    async getPaidAdsAllData(pagination = false, page = 1) {
      const res = await repository.index(
        "paid-advertisements",
        pagination,
        page
      );
      this.allData = res.data;
      await this.formatData(this.allData);
    },

    formatData(data) {
      console.log({ data });
      let name = null;
      data.forEach((d) => {
        name = d.department.name;
        d.department = null;
        d.department = name;
        d.created_at = moment(d.created_at).format("DD-MM-YYYY hh:mm a");
      });
    },
  },
};
</script>

<style scoped>
.data-info-container {
  display: flex;
  justify-content: space-between;
  direction: ltr;
}

.page-header .create-paid-ads {
  text-decoration: none;
  direction: ltr;
  font-size: 15px;
  font-weight: 500;
  color: #1a3f77;
}
</style>
