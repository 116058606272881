<template>
  <div class="container page-container py-3">
    <div class="page-header d-flex justify-content-between">
      <h4>{{ this.info?.title }}</h4>
      <router-link class="create-paid-ads" to="/paid-ads">
        عودة <img src="../../../assets/svg/back_icon.svg" />
      </router-link>
    </div>
    <div class="page-body d-flex flex-wrap px-4">
      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>اسم الوحدة الاعلانيه <span class="text-danger">*</span></h6>
          <input
            type="text"
            class="custom-text-input"
            placeholder="اسم الوحدة الاعلانيه"
            v-model="this.paidAdsData.name"
            @input="changeNameHandler"
          />
          <div class="text-danger">{{ this.errors.name }}</div>
        </div>

        <div class="w-50">
          <h6>مقاسات الوحدة الاعلانيه <span class="text-danger">*</span></h6>
          <div class="custom-select">
            <label
              ><span class="sr-only">Select a word:</span>
              <select
                @change="selectHandler($event, 'dimentions')"
                name="words"
                v-model="this.paidAdsData.dimensions"
              >
                <option value="" disabled>مقاسات الوحدة الاعلانيه</option>
                <option v-for="dim in this.dimentions" :value="dim">
                  {{ dim }}
                </option>
              </select>
            </label>
          </div>
          <div class="text-danger">{{ this.errors.dimentions }}</div>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>مكان الوحدة الاعلانيه</h6>
          <div class="custom-select">
            <label
              ><span class="sr-only">Select a word:</span>
              <select
                @change="selectHandler($event, 'place')"
                name="words"
                v-model="this.paidAdsData.department_id"
              >
                <option value="" disabled>مكان الوحدة الاعلانيه</option>
                <option
                  v-for="department in this.departments"
                  :value="department.id"
                  :key="department.id"
                >
                  {{ department.name }}
                </option>
              </select>
            </label>
          </div>
          <div class="text-danger">{{ this.errors.place }}</div>
        </div>

        <div class="w-50">
          <h6>صورة الاعلان</h6>
          <input
            type="text"
            class="custom-text-input"
            placeholder=""
            :value="
              this.paidAdsData.image.length > 0
                ? this.paidAdsData.image[0]['name']
                : 'لا يوجد صورة'
            "
            disabled
          />
          <div class="text-danger">{{ this.errors.files }}</div>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-100" @dragover.prevent @drop.prevent>
          <div
            class="files-container"
            @dragleave="fileDragOut"
            @dragover="fileDragIn"
            @drop="handleFileDrop"
          >
            <div
              class="file-wrapper"
              :style="{ 'background-color': this.color }"
            >
              <input
                type="file"
                name="file-input"
                multiple="false"
                accept="image/*"
                @change="handleFileInput"
                :title="
                  this.paidAdsData.image.length > 0
                    ? 'قم بتغيير الصورة'
                    : 'لا يوجد صورة'
                "
              />
              <img
                src="../../../assets/svg/add_photo_alternate_black_icon.svg"
              />
              <p>قم بسحب و اسقاط تصميم الاعلان هنا أو تصفح جهاز الكمبيوتر</p>
            </div>
            <ul class="files-info-container">
              <li
                class="d-flex gap-1"
                v-for="(file, index) in this.paidAdsData.image"
                :key="index"
              >
                {{ file.name }} ({{ Math.round(file.size / 1024) }} KB)
                <span class="close-icon" @click="removeFile(index)" title="حذف"
                  >X</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page-footer">
      <a class="btn-action send-btn" @click="saveDataHandler">قم بالارسال</a>
    </div>
  </div>

  <alertComponent
    v-if="this.alertFlag"
    @close-emit="this.alertFlag = false"
    :alertObj="this.getAlertObj()"
  />
</template>

<script>
import repository from "../../../api/repository";
import alertComponent from "@/components/globalComponents/alertComponent.vue";

export default {
  name: "common-component",
  props: ["paidAdsData", "info"],
  components: { alertComponent },
  async mounted() {
    await this.getDepartmentsAllData();
    console.log(this.departments);
    this.id = this.$route.params.id;
    this.$store.dispatch("setActivePage", "paid-ads");
  },
  data() {
    return {
      alertFlag: false,
      departments: [],
      dimentions: [
        "100 * 100",
        "100 * 150",
        "100 * 200",
        "200 * 200",
        "300 * 300",
        "200 * 150",
        "400 * 500",
        "500 * 500",
      ],
      alertObj: {
        icon: "success.svg",
        message: "ok",
      },
      id: null,
      fileName: "",
      color: "#FFF",

      errors: {
        name: "",
        dimentions: "",
        place: "",
        files: "",
      },

      data: {},
    };
  },
  methods: {
    async getDepartmentsAllData() {
      const res = await repository.departmentsIndex();
      this.departments = res.data.data;
    },
    getAlertObj() {
      return new Promise((resolve, reject) => {
        resolve(this.alertObj);
      });
    },
    handleFileDrop(e) {
      console.log("drag");
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...droppedFiles].forEach((f) => {
        this.paidAdsData.image = [f];
      });

      this.errors.files = "";

      this.color = "#FFF";
    },
    handleFileInput(e) {
      console.log("handleFileInput");
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...files].forEach((f) => {
        this.paidAdsData.image = [f];
      });

      console.log(this.paidAdsData);
      this.errors.files = "";
    },
    removeFile(fileKey) {
      this.paidAdsData.image.splice(fileKey, 1);
      this.errors.files = "* مطلوب";
    },
    fileDragIn() {
      this.color = "#CCC";
    },
    fileDragOut() {
      this.color = "#FFF";
    },

    changeNameHandler(e) {
      let val = e.target.value.trim();
      if (val.length > 0) {
        this.paidAdsData.name = val;
        this.errors.name = "";
      } else {
        this.paidAdsData.name = "";
        this.errors.name = " * مطلوب";
      }
    },
    selectHandler(e, key) {
      console.log("selectHandler");
      this.errors[key] = "";
    },
    async saveDataHandler() {
      console.log("paidAdsData =>> ", this.paidAdsData);
      console.log("info =>> ", this.info);

      for (const [key, value] of Object.entries(this.paidAdsData)) {
        if (key !== "image") {
          if (value === "") {
            this.errors[key] = "* مطلوب";
          } else {
            this.errors[key] = "";
          }
        } else {
          if (value.length === 0) {
            this.errors[key] = "* مطلوب";
          } else {
            this.errors[key] = "";
          }
        }
      }

      if (
        this.errors["name"].length === 0 &&
        this.errors["dimentions"].length === 0 &&
        this.errors["place"].length === 0 &&
        this.errors["files"].length === 0
      ) {
        const formData = new FormData();

        formData.append("name", this.paidAdsData.name);
        formData.append("dimensions", this.paidAdsData.dimensions);

        formData.append("department_id", this.paidAdsData.department_id);

        formData.append("image", this.paidAdsData.image[0]);

        try {
          if(this.info.action === 'create'){
             await repository.create("paid-advertisements", formData);

             this.paidAdsData.name = "";
             this.paidAdsData.dimensions = "";
             this.paidAdsData.department_id = "";
             this.paidAdsData.image = [];

            this.alertObj = {
              icon: "success.svg",
              message: "تم انشاء الاعلان بنجاح",
            };
            this.alertFlag = true;
          }else{
             await repository.update("paid-advertisements", this.info.id, formData);
            this.alertObj = {
              icon: "success.svg",
              message: "تم تعديل الاعلان بنجاح",
            };
            this.alertFlag = true;

            setTimeout(()=>{
              this.$router.push({ path: "/paid-ads" });

            }, 2000)
          }
          // console.log({ res });
        } catch (err) {
          this.alertObj = {
              icon: "error.svg",
              message: "خطا",
            };
            this.alertFlag = true;
          console.log({ err });
        }
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  height: 75vh;
}
.page-body {
  background-color: #fff;
  margin-top: 10px;
  height: 100%;
}

.page-footer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.data-row-container {
  height: fit-content;
}

.send-btn {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: #1a3f77;
}

.page-header .create-paid-ads {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #1a3f77;
}
/* select */
.custom-select {
  width: 90%;
  height: fit-content;
  padding: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  appearance: none;
  border-width: 2px;
  border-color: transparent;
  box-sizing: border-box;
  padding-right: 10px;
  height: 2rem;
  flex-grow: 1;
}
select:focus {
  outline: none;
  border-color: none;
}
label {
  position: relative;
  width: 100%;
  /* min-width: 10em;
  max-width: 18em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 128, 0, 0.5);
}
label::after {
  /* content: '\25BE'; */
  content: url("../../../assets/svg/expand_more_black_icon.svg");
  background-color: var(--focused-border-color);
  color: #fff;
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24px;
  padding-right: 25px;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.custom-text-input {
  width: 90%;
  padding: 0 10px;
  height: 54px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-text-input:focus {
  outline: none;
}

::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

/* select */
/* upload File */
.file-wrapper {
  text-align: center;
  width: 95%;
  vertical-align: middle;
  /* display: table-cell; */
  position: relative;
  overflow: hidden;
  background: #fff; /* and other things to make it pretty */
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 165px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.file-wrapper input {
  position: absolute;
  top: 0;
  right: 0; /* not left, because only the right part of the input seems to
                 be clickable in some browser I can't remember */
  cursor: pointer;
  opacity: 0;
  filter: alpha(
    opacity=0
  ); /* and all the other old opacity stuff you
                                 want to support */
  font-size: 300px; /* wtf, but apparently the most reliable way to make
                         a large part of the input clickable in most browsers */
  height: 200px;
}

/* upload File */

.files-info-container {
  margin-top: 10px;
  list-style: none;
}

.files-container {
  height: 180px;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #dc3545;
  color: #fff;
  font-size: 10px;
}
</style>
