<template>
  <div class="confirm-modal" v-if="this.data">
    <div class="d-flex flex-column gap-3 modal-container">
      <div class="modal-icon" :style="{color:this.data?.icon.color, fontSize: '30px'}">
        <font-awesome-icon :icon="this.data?.icon.name" />
      </div>

      <div class="modal-body p-0">
        {{this.data?.confirmMessage}}
      </div>

      <div v-if="this.data?.type === 'delete' && this.data?.isReason">
        <p>اذكر سبب الحذف</p>
        <textarea name="" id="" cols="30" rows="10" placeholder="يمكنك ذكر سبب الحذف لصاحب المكان هنا"></textarea>
      </div>

      <div class="d-flex justify-content-evenly  modal-footer">
        <a @click="onOk($event, this.data)" :style="{color:this.data?.footer?.accept?.color, backgroundColor: this.data?.footer?.accept?.bgColor}">{{this.data?.footer.accept.text}}</a>
        <a @click="onCancle($event, this.data)" :style="{color:this.data?.footer?.reject?.color, backgroundColor: this.data?.footer?.reject?.bgColor}">{{this.data?.footer.reject.text}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirm-component',
  props:['confirmObj'],
  data() {
    return{

      data: null
    }
  },

  mounted(){
this.confirmObj.then(res => {
  console.log("conf => ", res);
  this.data = res
});

},

methods:{
  onOk(e, action){

    this.$emit('close-emit')
    action?.onAcceptConfirm()
  },
  onCancle(e, action){

this.$emit('close-emit')
action?.onCancleConfirm()
}
}

}
</script>

<style scoped>

.confirm-modal{
  position: absolute;
    top: 0;
    right:0;
    width: 100%;
    height: 100%;
    background-color: #000000e8;
    color: #FFF;
    z-index: 1000;
    display: flex;
    justify-content: center; 
}

.modal-container{
  width: 40%;
    height: fit-content;
    background-color: #FFF;
    color: #000;
    padding: 15px;
}

textarea{
  min-height: 75px;
  max-height: 75px;
  width: 100%;
  overflow-y: auto;
  border: 1px solid #DDD;
}
.modal-icon{
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #DDD;
}
.modal-footer a {
  text-align: center;
  display: inline-block;
  width: 140px;
  border: 1px solid #DDD;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}
</style>