<template>
  <div class="container page-container py-3">
    <div class="page-header">
      <h4>{{ this.data["place_name"] }}</h4>
    </div>
    <div class="page-body d-flex flex-wrap px-4">
      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>اسم المكان</h6>
          <p>{{ this.data["place_name"] }}</p>
        </div>

        <div class="w-50">
          <h6>المدينة</h6>
          <p>{{ this.data["city"] }}</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>القسم</h6>
          <p>{{ this.data["department"] }}</p>
        </div>

        <div class="w-50">
          <h6>ايميل التواصل</h6>
          <p>"...."</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>اسم المستخدم</h6>
          <p>{{ this.data["user_name"] }}</p>
        </div>

        <div class="w-50">
          <h6>رقم الهاتف</h6>
          <p>"....."</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>رقم الواتساب</h6>
          <p>{{ this.data["place_name"] }}</p>
        </div>

        <div class="w-50">
          <h6>حساب الفيسبوك</h6>
          <p>{{ this.data["city"] }}</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>مواعيد العمل</h6>
          <p>"...."</p>
        </div>

        <div class="w-50">
          <h6>المكان علي الخريطه</h6>
          <p>"...."</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-100">
          <h6>العنوان</h6>
          <p>"...."</p>
        </div>
      </div>


      <div class="data-row-container w-100 d-flex">
        <div class="w-100">
          <h6>الوصف والتفاصيل</h6>
          <p>{{ this.data["place_name"] }}</p>
        </div>
      </div>

      <div class="data-row-container w-100 d-flex">
        <div class="w-100">
          <h6>صور المكان</h6>
          <p>"...."</p>
        </div>
      </div>
    </div>

    <div class="page-footer">
      <a class="btn-action accept-btn">قبول المكان</a>
      <a class="btn-action reject-btn">رفض المكان</a>
    </div>
  </div>
</template>

<script>
import repository from "../../api/repository";

export default {
  async mounted() {
    this.id = this.$route.params.id;
    this.$store.dispatch("setActivePage", "advertising");
    const res = await repository.show("incoming-advertising-requests", this.id);

    this.data = res.data;
  },
  data() {
    return {
      id: null,
      data: {},
    };
  },
  methods: {
    async getIncomingAdvertisingRequest(id) {
      const res = await repository.index(
        "incoming-advertising-requests",
        true,
        page
      );
      if (res.status === 200) {
        this.isData = true;
        this.laravelData = res.data;
        this.tData = res.data.data;
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  height: 75vh;
}
.page-body {
  background-color: #fff;
  margin-top: 10px;
  height: 100%;
}

.page-footer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.btn-action {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}

.accept-btn {
  background-color: #369f3a;
}

.reject-btn {
  background-color: #d54834;
}

.data-row-container{
  height: fit-content;
  border-bottom: 1px solid #CCC
}

.data-row-container:last-child{
  border-bottom: none
}

.data-row-container p {
  padding: 0;
  margin: 0;
}

.data-row-container div{
  margin: 10px 0;
}
</style>
