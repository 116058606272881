<template>
  <div class="container py-3">
    <div class="page-header d-flex justify-content-between">
      <h4>ادارة المناطق</h4>
      <router-link class="create" to="/regions/create">انشاء منطقة   <img src="../../assets/svg/plus_icon.svg" /> </router-link>
    </div>
    <div class="page-body">
      <tableComponent
        :columns="columns"
        :title="tableTitle"
        :mainAction="tableAction"
        :tHead="this.tHead"
        :keys="keys"
        :tData="tData"
        :allData="allData"
        :actions="actions"
        :isHeader="false"
        :isHeaderAction="true"
        :headerActions="headerActions"
        excleFileName="المناطق"
      />

      <div class="data-info-container">
        <Pagination
          :class="pagination - style"
          :data="laravelData"
          align="right"
          :show-disabled="true"
          :limit="2"
          @pagination-change-page="getRegions"
        >
          <template #prev-nav>
            <span>السابق</span>
          </template>
          <template #next-nav>
            <span>التالي</span>
          </template>
        </Pagination>

        <paginationInfoComponent v-if="isData" :data="infoData" />

      </div>
    </div>
  </div>

  <alertComponent
    v-if="this.alertFlag"
    @close-emit="this.alertFlag = false"
    :alertObj="this.getAlertObj()"
  />

</template>

<script>
import tableComponent from "@/components/globalComponents/tableComponent.vue";
import repository from "../../api/repository";
import LaravelVuePagination from "laravel-vue-pagination";
import alertComponent from "@/components/globalComponents/alertComponent.vue";
import paginationInfoComponent from "../../components/globalComponents/paginationInfoComponent.vue";


export default {
  components: { tableComponent, Pagination: LaravelVuePagination, alertComponent, paginationInfoComponent },

  async mounted() {
    this.$store.dispatch("setActivePage", "regions");

    this.getRegionsAllData();

    this.getRegions();
  },
  data() {
    return {
      alertFlag: false,
      isData: false,
      infoData: {},
      allData: [],
      laravelData: {},
      tableTitle: "المناطق",
      tableAction: {
        name: "عرض جميع الطلبات",
        path: "/incoming-advertising-requests",
      },
      tHead: [
        "اسم المنطقه",
        "",
      ],
      keys: [
        "name",
      ],

      columns: [
        {
          label: "رقم",
          field: "id",
        },
        {
          label: "اسم المنطقه",
          field: "name",
        }
      ],
      actions: [
        {
          url: "#",
          icon: "svg/trashIcon.svg",
          title: "حذف الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            let confirmObj = {
              icon: {
                name: "fa fa-trash",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#d54834",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              isReason: false,
              confirmMessage: "هل ترغب بالفعل في حذف المنطقه؟",
              onAcceptConfirm: async () => {
                try{
                  await repository.destroy("regions", id);
                  await this.$store.dispatch("setConfirmFlag", false);
                  this.test();
          
                }catch{

                }
                
              },
              onCancleConfirm: async () => {
                console.log("no");
                await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            // await this.$store.dispatch("setConfirmObject", confirmObj);
            return confirmObj;
          },
        },
        {
          url: "#",
          icon: "svg/editIcon.svg",
          title: "تعديل المنطقه",
          flag: "confirm",
          onClickHandler: async (id) => {
            this.$router.push({ path: `/regions/update/${id}` });
            console.log({ id });
          },
        },
      ],
      tData: [],
      headerActions: {
        search: {
          name: "search",
          placeholder: "ابحث في المناطق",
          searchCB: async (search) => {
            const res = await repository.index(
              "regions",
              true,
              1,
              search
            );
            console.log({ res });

            if (res.status === 200) {
              console.log({ res });
              this.laravelData = res.data;
              this.tData = res.data.data;

              this.infoData = {
                total: this.laravelData.meta.total,
                from: this.laravelData.meta.from,
                to: this.laravelData.meta.to,
              };
              if (this.infoData.total > 8) this.isData = true;
        // this.isData = res.data.length > 0;

            }
            // await this.dataFormat( this.tData);
          },
        },
      },
    };
  },
  methods: {

    async test(){
      await  this.getRegions();
      this.alertObj = {
              icon: "success.svg",
              message: "تم حذف المنطقه بنجاح",
            };
            this.alertFlag = true;
                 
    },
    getAlertObj() {
      return new Promise((resolve, reject) => {
        resolve(this.alertObj);
      });
    },
    async getRegions(page = 1) {
      const res = await repository.index(
        "regions",
        true,
        page
      );
      if (res.status === 200) {
       
        this.laravelData = res.data;
        this.tData = res.data.data;

        this.infoData = {
                total: this.laravelData.meta.total,
                from: this.laravelData.meta.from,
                to: this.laravelData.meta.to,
              };
              if (this.infoData.total > 8) this.isData = true;
      }
    },

    async getRegionsAllData(pagination = false, page = 1) {
      const res = await repository.index(
        "regions",
        pagination,
        page
      );
      this.allData = res.data.data;
    },
  },
};
</script>

<style scoped>
.data-info-container {
  display: flex;
  justify-content: space-between;
  direction: ltr;
}

.page-header .create{
  text-decoration: none;
    direction: ltr;
    font-size: 15px;
    font-weight: 500;
    color: #1a3f77;
}
</style>
