<template>
 <nav id="sidebarMenu" class="d-md-block sidebar collapse">
  <div class="text-center    ">
    <img src="../assets/svg/Component112.svg" />

  </div>
      <div class="position-sticky pt-3">
        <ul class="nav flex-column text-white">
          <li class="nav-item">
            <router-link to="/" class="nav-link" :class="{active: this.activePage === 'home'}">
              <span>
                <font-awesome-icon icon="fa-solid fa-house" />
              </span>
              الصفحة الرئيسية
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/incoming-advertising-requests" class="nav-link" :class="{active: this.activePage === 'advertising'}">
              <span class="material-symbols-outlined">featured_video</span>
               طلبات الإعلان الواردة
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/paid-ads" class="nav-link" :class="{active: this.activePage === 'paid-ads'}">
              <span class="material-symbols-outlined">calendar_today</span> 
              الإعلانات المدفوعة
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/users" class="nav-link" exact-active-class="active">
              <span>
                <font-awesome-icon icon="fa-solid fa-users" />
              </span>
              إدارة المستخدمين
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/regions" class="nav-link" :class="{active: this.activePage === 'regions'}">
              <span class="material-symbols-outlined">add_location_alt</span>
              إدارة المناطق
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/departments" class="nav-link" :class="{active: this.activePage === 'departments'}">
              <span class="material-symbols-outlined">menu</span>
              إدارة الأقسام
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/notifications" class="nav-link" exact-active-class="active">
            <span class="material-symbols-outlined">notification_add</span>
              إدارة الإشعارات
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/contact-requests" class="nav-link" exact-active-class="active">
              <span class="material-symbols-outlined">description</span>
              طلبات التواصل 
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/reports" class="nav-link" exact-active-class="active">
              <span class="material-symbols-outlined">grouped_bar_chart</span>
              التقارير
            </router-link>
          </li>
        
        </ul>

       
      </div>
    </nav>
</template>

<script>
 import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters({
      activePage: 'activePage',
    })
  }
};
</script>

<style scoped>

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.sidebar {
  width:200px;
  position: fixed;
  top: -40px;
  right: 0;
  bottom: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  /* box-shadow: inset 1px 0 0 rgba(0, 0, 0, .1); */
  background-color: var(--appMainColor);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 65px;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  padding: 14px;
  font-weight: 500;
  color: #FFF;
}

.sidebar .nav-link .feather {
  margin-left: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  background-color: #0000004f;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 200,
  'GRAD' 200,
  'opsz' 40
}
.nav-link{
  display: flex;
  justify-content: start;
  align-content: center;
  align-items: center;
  gap: 10px;
}
</style>

