import { createApp } from "vue";
import { createStore } from "vuex";
import repository from "../api/repository";

export default createStore({
  modules: {
    auth: {
      state: {
        user: sessionStorage.user
          ? JSON.parse(sessionStorage.getItem("user"))
          : null,
      },

      getters: {
        user: (state) => state.user,
        authenticated: (state) => state.user !== null,
      },

      mutations: {
        SET_USER(state, user) {
          state.user = user;
        },
      },

      actions: {
        async login({ commit }, user) {
          await repository.createSession();
          const { data } = await repository.login(user);
          commit("SET_USER", data.data.info);

          // sessionStorage.user = JSON.stringify(data);
          sessionStorage.setItem("user", JSON.stringify(data.data.info));
        },

        async logout({ commit }) {
          await repository.logout();
           commit("SET_USER", null);
           sessionStorage.removeItem("user");
         
        },
      },
    },

    global: {
      state: {
        activePage: "",
        isLoading: false,
        confirmFlag: false,
        confirmObject: {
          icon: {
            name: "",
            color: "",
          },
          body: {},
          footer: {
            accept: {
              text: "",
              color: "",
              bgColor: "",
            },
            reject: {
              text: "",
              color: "",
              bgColor: "",
            },
          },
          type: "",
          confirmMessage: "",
          onAcceptConfirm: null,
          onCancleConfirm: null,
        },

        alertObject:{
            icon:'',
            message:''
        }
      },

      getters: {
        confirmObject: (state) => state.confirmObject,
        confirmFlag: (state) => state.confirmFlag,
        
        alertObject: (state) => state.alertObject,
        alertFlag: (state) => state.alertFlag,
        isLoading: (state) => state.isLoading,
        activePage: (state) => state.activePage,

      },

      mutations: {
        SET_CONFIRM_FLAG(state, flag) {
          state.confirmFlag = flag;
        },
        SET_CONFIRM_OBJECT(state, obj) {
          state.confirmObject = obj;
        },
        SET_ALERT_FLAG(state, flag) {
          state.alertFlag = flag;
        },
        SET_ALERT_OBJECT(state, obj) {
          state.alertObject = obj;
        },

        SET_IS_LOADING(state, data) {
          state.isLoading = data;
        },

        
        SET_ACTIVE_PAGE(state, pageName) {
          state.activePage = pageName;
        },
      },

      actions: {
        setConfirmFlag({ commit }, data) {
          commit("SET_CONFIRM_FLAG", data);
        },
        setConfirmObject({ commit }, data) {
          commit("SET_CONFIRM_OBJECT", data);
        },
        setAlertFlag({ commit }, data) {
          commit("SET_ALERT_FLAG", data);
        },
        setAlertObject({ commit }, data) {
          commit("SET_ALERT_OBJECT", data);
        },
        setIsLoading({ commit }, data) {
          commit("SET_IS_LOADING", data);
        },
        setActivePage({ commit }, pageName) {
          commit("SET_ACTIVE_PAGE", pageName);
        }
      },
    },
  },
});
