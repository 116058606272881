<template>
  <div class="container py-3">
    <div class="page-header d-flex justify-content-between">
      <h4>ادارة الاقسام</h4>
      <router-link class="create" to="/departments/create"
        >انشاء قسم <img src="../../assets/svg/plus_icon.svg" />
      </router-link>
    </div>
    <div class="page-body">
      <tableComponent
        :columns="columns"
        :title="tableTitle"
        :mainAction="tableAction"
        :tHead="this.tHead"
        :keys="keys"
        :tData="tData"
        :allData="allData"
        :actions="actions"
        :isHeader="false"
        :isHeaderAction="true"
        :headerActions="headerActions"
        excleFileName="الاقسام"
      />

      <div class="data-info-container">
        <Pagination
          :class="pagination - style"
          :data="laravelData"
          align="right"
          :show-disabled="true"
          :limit="2"
          @pagination-change-page="getDepartments"
        >
          <template #prev-nav>
            <span>السابق</span>
          </template>
          <template #next-nav>
            <span>التالي</span>
          </template>
        </Pagination>

        <paginationInfoComponent v-if="isData" :data="infoData" />
      </div>
    </div>
  </div>

  <alertComponent
    v-if="this.alertFlag"
    @close-emit="this.alertFlag = false"
    :alertObj="this.getAlertObj()"
  />
</template>

<script>
import tableComponent from "@/components/globalComponents/tableComponent.vue";
import repository from "../../api/repository";
import LaravelVuePagination from "laravel-vue-pagination";
import alertComponent from "@/components/globalComponents/alertComponent.vue";
import paginationInfoComponent from "../../components/globalComponents/paginationInfoComponent.vue";

export default {
  components: {
    tableComponent,
    Pagination: LaravelVuePagination,
    alertComponent,
    paginationInfoComponent,
  },

  async mounted() {
    this.$store.dispatch("setActivePage", "departments");

    this.getDepartmentsAllData();

    this.getDepartments();
  },
  data() {
    return {
      infoData: {},
      alertFlag: false,
      isData: false,
      allData: [],
      laravelData: {},
      tableTitle: "الاقسام",
      tableAction: {
        name: "عرض جميع الطلبات",
        path: "/incoming-advertising-requests",
      },
      tHead: ["اسم القسم", "الاقسام الفرعيه", ""],
      keys: ["name", "children"],

      columns: [
        {
          label: "رقم",
          field: "id",
        },
        {
          label: "اسم القسم",
          field: "name",
        },
        {
          label: "الاقسام الفرعيه",
          field: "children",
        },
      ],
      actions: [
        {
          url: "#",
          icon: "svg/trashIcon.svg",
          title: "حذف الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            let confirmObj = {
              icon: {
                name: "fa fa-trash",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#d54834",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              isReason: false,
              confirmMessage: "هل ترغب بالفعل في حذف القسم؟",
              onAcceptConfirm: async () => {
                try {
                  await repository.destroy("departments", id);
                  await this.$store.dispatch("setConfirmFlag", false);
                  this.deleteFn("success");
                } catch {
                  this.deleteFn("fail");
                }
              },
              onCancleConfirm: async () => {
                console.log("no");
                await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            // await this.$store.dispatch("setConfirmObject", confirmObj);
            return confirmObj;
          },
        },
        {
          url: "#",
          icon: "svg/editIcon.svg",
          title: "تعديل المنطقه",
          flag: "confirm",
          onClickHandler: async (id) => {
            this.$router.push({ path: `/departments/update/${id}` });
            console.log({ id });
          },
        },
      ],
      tData: [],
      headerActions: {
        search: {
          name: "search",
          placeholder: "ابحث في الاقسام",
          searchCB: async (search) => {
            const res = await repository.index("departments", true, 1, search);
            console.log({ res });

            if (res.status === 200) {
              console.log({ res });
              this.laravelData = res.data;
              this.tData = res.data.data;
              if (this.tData.length > 0) this.isData = true;
              this.infoData = {
                total: this.laravelData.meta.total,
                from: this.laravelData.meta.from,
                to: this.laravelData.meta.to,
              };
            }
            await this.dataFormat(this.tData);
          },
        },
      },
    };
  },
  methods: {
    async deleteFn(status) {
      await this.getDepartments();
      await this.getDepartmentsAllData();
      if (status === "success") {
        this.alertObj = {
          icon: "success.svg",
          message: "تم حذف القسم بنجاح",
        };
      } else {
        this.alertObj = {
          icon: "error.svg",
          message: "لا يمكنك حذف القسم لاحتواءه علي اقسام فرعيه",
        };
      }

      this.alertFlag = true;
    },
    getAlertObj() {
      return new Promise((resolve, reject) => {
        resolve(this.alertObj);
      });
    },
    async getDepartments(page = 1) {
      const res = await repository.index("departments", true, page);
      if (res.status === 200) {
        this.laravelData = res.data;
        this.tData = res.data.data;

        this.isData = res.data.data.length > 0;
        this.infoData = {
          total: this.laravelData.meta.total,
          from: this.laravelData.meta.from,
          to: this.laravelData.meta.to,
        };
        this.dataFormat(this.tData);
      }
    },
    dataFormat(data) {
      let newData = null;
      let names = "";
      data.forEach((d) => {
        if (d.children.length > 0) {
          d.children.forEach((child, idx, array) => {
            if (idx === array.length - 1) names += child.name;
            else names += child.name + ", ";
          });

          d.children = names;
          names = "";
        } else {
          d.children = "لا يوجد";
        }
      });

      console.log({ names });
    },

    async getDepartmentsAllData(pagination = false, page = 1) {
      const res = await repository.index("departments", pagination, page);
      this.allData = res.data.data;
      console.log("dd ", this.allData);

      await this.dataFormat(this.allData);
      console.log("dd ", this.allData);
    },
  },
};
</script>

<style scoped>
.data-info-container {
  display: flex;
  justify-content: space-between;
  direction: ltr;
}

.page-header .create {
  text-decoration: none;
  direction: ltr;
  font-size: 15px;
  font-weight: 500;
  color: #1a3f77;
}
</style>
