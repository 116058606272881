<template>
  <div class="d-flex flex-column gap-3">
    <div class="header-container">
      <h4>لوحة التحكم</h4>
    </div>

    <div class="information-container">
      <div class="information-content">
        <div>
          <div class="information-text">اجمالي المستخدمين</div>
          <div class="information-number">{{ this.usersCount }}</div>
        </div>
        <span class="information-icon material-symbols-outlined">group</span>
      </div>

      <div class="information-content">
        <div>
          <div class="information-text">اجمالي الأرباح</div>
          <div class="information-number">
            {{ this.profits }} <span>ج.م</span>
          </div>
        </div>
        <span class="information-icon material-symbols-outlined">menu</span>
      </div>

      <div class="information-content">
        <div>
          <div class="information-text">اجمالي الوحدات الاعلانيه</div>
          <div class="information-number">{{ this.adsCount }}</div>
        </div>
        <span class="information-icon material-symbols-outlined"
          >calendar_today</span
        >
      </div>

      <div class="information-content">
        <div>
          <div class="information-text">اجمالي الأماكن المضافه</div>
          <div class="information-number">{{ this.locationsCount }}</div>
        </div>
        <span class="information-icon material-symbols-outlined"
          >add_location_alt</span
        >
      </div>
    </div>

    <tableComponent
    :columns="columns"
      :title="tableTitle"
      :mainAction="tableAction"
      :tHead="this.tHead"
      :keys="keys"
      :tData="tData"
      :actions="actions"
      :isHeader="true"
      :isHeaderAction="false"
    />
  </div>
</template>

<script>
import tableComponent from "@/components/globalComponents/tableComponent.vue";
export default {
  name: "home-index",
  components: { tableComponent },
  async mounted() {
    this.$store.dispatch("setActivePage", "home");

    this.getIncomingAdvertisingRequests();
  },

  data() {
    return {
      usersCount: 13456,
      profits: 40000,
      adsCount: 16,
      locationsCount: 3,
      tableTitle: "أحدث الطلبات الواردة",
      tableAction: {
        name: "عرض جميع الطلبات",
        path: "/incoming-advertising-requests",
      },
      tHead: ["اسم المكان", "اسم المستخدم", "المدينة", "التاريخ", "القسم", ""],
      keys: ["placeName", "userName", "city", "date", "department"],

      columns : [
                    {
                        label: "اسم المكان",
                        field: "placeName",
                    },
                    {
                        label: "اسم المستخدم",
                        field: "userName",
                    },
                    {
                        label: "المدينة",
                        field: "city",
                    },
                    {
                        label: "التاريخ",
                        field: "date",
                    },
                    {
                        label: "القسم",
                        field: "department",
                    }
                ],
      actions: [
        {
          url: "#",
          icon: "confirmIcon.svg",
          title: "قبول الطلب",
          flag: 'alert',
          onClickHandler: async () => {
            console.log("confirm");

            let alertObj = {
             
              icon:'success.svg',
              message: 'ok',
            }


            // await this.$store.dispatch("setAlertFlag", true);

            return alertObj;
            // await this.$store.dispatch("setAlertObject", alertObj);
          },
        },
        {
          url: "#",
          icon: "viewIcon.svg",
          title: " عرض الطلب",
          flag: 'confirm',
          onClickHandler: async(id)=>{
            let confirmObj = {
              icon: {
                name: "fa fa-pen",
                color: "#00F",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#00F",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              confirmMessage: "هل ترغب بالفعل في حذف طلب اضافة المكان؟",
              onAcceptConfirm: async () => {
                console.log("ok", id);
              },
              onCancleConfirm: async () => {
                console.log("no");
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            return confirmObj;
          },
        },
        {
          url: "#",
          icon: "trashIcon.svg",
          title: "حذف الطلب",
          flag: "confirm",
          onClickHandler: async (id) => {
            
            let confirmObj = {
             
              icon: {
                name: "fa fa-trash",
                color: "#d54834",
              },
              body: {},
              footer: {
                accept: {
                  text: "نعم, أرغب",
                  color: "#FFF",
                  bgColor: "#d54834",
                },
                reject: {
                  text: "عودة",
                  color: "#000",
                  bgColor: "#fff",
                },
              },
              type: "delete",
              confirmMessage: "هل ترغب بالفعل في حذف طلب اضافة المكان؟",
              onAcceptConfirm: async () => {
                console.log("ok", id);
                await this.$store.dispatch("setConfirmFlag", false);
              },
              onCancleConfirm: async () => {
                console.log("no");
                await this.$store.dispatch("setConfirmFlag", false);
              },
            };
            await this.$store.dispatch("setConfirmFlag", true);
            // await this.$store.dispatch("setConfirmObject", confirmObj);
            return confirmObj;

          },
        },
      ],
      tData: [],
    };
  },
  methods:{
    async getIncomingAdvertisingRequests(page = 1) {

const res = await repository.index("incoming-advertisement-requests",true, page);
if(res.status === 200)
  this.tData = res.data.data


},
  }
};
</script>

<style scoped>
.information-container {
  display: flex;
  gap: 10px;
  padding: 10px;
}
.information-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  padding: 15px;
  background-color: #fff;
}

.information-text {
  font-weight: 800;
  color: darkslateblue;
}

.information-number {
  font-weight: 900;
}

.information-icon {
  border-radius: 8px;
  padding: 10px;
  color: darkslateblue;
  background-color: #ddd;
}
.header-container {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.information-container {
  display: flex;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 200, "GRAD" 200, "opsz" 40;
}
</style>
