<template>
  <div class="d-flex login-container">
    <div class="right-side gap-4 w-75">


      <div>
        <img src="../../assets/svg/right_side_logo.svg" alt="" srcset="" />
      </div>

      <div class="d-flex flex-column w-50">
        <p>مرحبا بعودتك! يرجي تسجيل الدخول الي حسابك</p>
      </div>

      <p v-if="error" style="color: red">{{ error }}</p>

      <div class="d-flex flex-column gap-3 w-50">
        <form v-on:keyup.enter="login">
          <div class="form-floating mb-3">
            <input
              type="email"
              class="form-control"
              id="email"
              @input="isValidData"
              placeholder="name@example.com"
              v-model="user.email"
            />
            <label for="email">البريد الالكتروني</label>
            <div class="text-danger" v-if="errors.email.length > 0">
              {{ errors.email[0] }}
            </div>
          </div>

          <div class="form-floating mb-3">
            <input
              :type="this.isHidden ? 'password' : 'text'"
              @input="isValidData"
              class="form-control"
              id="password"
              placeholder="Password"
              v-model="user.password"
            />
            <label for="password">كلمه المرور</label>

            <span class="show-pass-btn" @click="onShowPass">{{
              this.passwordShowHideTag
            }}</span>

            <div class="text-danger" v-if="errors.password.length > 0">
              {{ errors.password[0] }}
            </div>
          </div>

          <div class="form-check">
            <input
          
              class="form-check-input"
              type="checkbox"
              v-model="user.remember_me"
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
              تذكرني لاحقا
            </label>
          </div>
        </form>
      </div>

      <div class="w-50">
        <button class="w-100 login-btn"  @click="login">تسجيل الدخول</button>
      </div>

      <div>
        <router-link to="/forget-password">نسيت كلمة المرور؟</router-link>
      </div>
    </div>
    <div class="left-side w-50"></div>

  </div>

</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      isHidden: true,
      passwordShowHideTag: "اظهر",
      user: {
        email: '',
        password: '',
        remember_me: false,
      },
      errors:{
        email: [],
        password: []
      },
      loading: false,
      error: null,
    };
  },

  methods: {
    async login() {
      this.error = null;
      try {
        if(this.isValidData()){
          this.loading = true;
        await this.$store.dispatch("login", this.user);
        await this.$router.push({ path: "/" });
        }

      } catch (error) {
        console.log({ error });
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },
    onShowPass() {
      this.isHidden ^= true;

      this.passwordShowHideTag = this.isHidden ? "اظهر" : "اخفي";
    },

    isValidData(){
      let isValidEmail = false;
      let isEmail = false;
      let isPassword = false;
      if(this.user.email.length > 0){
        isEmail = true;
        this.errors.email = [];
        if(this.validateEmail(this.user.email)){
          isValidEmail = true;
        this.errors.email = [];
        }else{
          isValidEmail = false;
        this.errors.email = ['* يرجي ادخال بريد الكترونى '];
        }
      }else{
        isEmail = false;
        this.errors.email = ['* مطلوب'];
      }

      if(this.user.password.length > 0){
        isPassword = true;

        this.errors.password = [];
      }else{
        isPassword = false;

        this.errors.password = ['* مطلوب'];
      }

      console.log(isValidEmail, isEmail, isPassword);
        return isValidEmail && isEmail && isPassword;
    },
     validateEmail (email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
  },
};
</script>

<style scoped>

  .test{
    background-color: var(--appMainColor);
    color:#FFF;
    cursor: pointer;
  }
.login-container {
  height: 100vh;
}
.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 40px;
}
.left-side {
  background-image: url("../../assets/svg/login_left_side.svg");
  background-position: center;
  background-size: cover;
}

.login-btn {
  padding: 15px;
  border: none;
  background-color: var(--appMainColor);
  color: #fff;
  border-radius: 4px;
}

.show-pass-btn {
  position: absolute;
  top: 13px;
  left: 0;
  cursor: pointer;
}

#email,
#email:focus,
#password,
#password:focus {
  border: none;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 0 0;
}

@media screen and (max-width: 600px) {
  .left-side {
    display: none;
  }
  .right-side {
    width: 100% !important;
  }
}
</style>
