<template>
  <div class="container page-container py-3">
    <div class="page-header d-flex justify-content-between">
      <h4>{{ this.info?.title }}</h4>
      <router-link class="create-paid-ads" to="/regions">
        عودة <img src="../../../assets/svg/back_icon.svg" />
      </router-link>
    </div>
    <div class="page-body d-flex flex-wrap px-4">
      <div class="data-row-container w-100 d-flex">
        <div class="w-50">
          <h6>اسم المنطقه <span class="text-danger">*</span></h6>
          <input
            type="text"
            class="custom-text-input"
            placeholder="اسم المنطقه"
            v-model="this.regionData.name"
            @input="changeNameHandler"
          />
          <div class="text-danger">{{ this.errors.name }}</div>
        </div>
      </div>
    </div>

    <div class="page-footer">
      <a class="btn-action send-btn" :style="{backgroundColor: this.info.actionBtn.bgColor}" @click="saveDataHandler">{{this.info.actionBtn.title}}</a>
    </div>
  </div>
  <alertComponent
    v-if="this.alertFlag"
    @close-emit="this.alertFlag = false"
    :alertObj="this.getAlertObj()"
  />
</template>

<script>
import repository from "../../../api/repository";
import alertComponent from "@/components/globalComponents/alertComponent.vue";

export default {
  name: "common-component",
  props: ["regionData", "info"],
  components: { alertComponent },
  async mounted() {
    this.id = this.$route.params.id;
    this.$store.dispatch("setActivePage", "regions");
  },
  data() {
    return {
      alertFlag: false,
      alertObj: {
        icon: "success.svg",
        message: "ok",
      },
      id: null,
      fileName: "",
      color: "#FFF",
      errors: {
        name: "",
      },
    };
  },
  methods: {
    getAlertObj() {
      return new Promise((resolve, reject) => {
        resolve(this.alertObj);
      });
    },
    changeNameHandler(e) {
      let val = e.target.value.trim();
      if (val.length > 0) {
        this.regionData.name = val;
        this.errors.name = "";
      } else {
        this.regionData.name = "";
        this.errors.name = " * مطلوب";
      }
    },

    async saveDataHandler() {
      console.log("regionData =>> ", this.regionData);
      console.log("info =>> ", this.info);

      if (this.regionData["name"] === "") {
        this.errors["name"] = "* مطلوب";
      } else {
        this.errors["name"] = "";
        try {
          if (this.info.action === "create") {
            const res = await repository.create("regions", this.regionData);
            this.regionData["name"] = "";
            this.alertObj = {
              icon: "success.svg",
              message: "تم انشاء المنطقه بنجاح",
            };
            this.alertFlag = true;
          }else{
            const res = await repository.update("regions", this.info.id ,this.regionData);
            this.alertObj = {
              icon: "success.svg",
              message: "تم تعديل المنطقه بنجاح",
            };
            this.alertFlag = true;

            setTimeout(()=>{
              this.$router.push({path: '/regions'})
            }, 2000)

          
          }
        } catch (err) {
          console.log({err});
          this.alertObj = {
            icon: "error.svg",
            message: "خطا لقد تم ادخال اسم المنطقه من قبل",
          };
          this.alertFlag = true;
          this.errors["name"] = "* خطا لقد تم ادخال اسم المنطقه من قبل";
        }
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  height: 75vh;
}
.page-body {
  background-color: #fff;
  margin-top: 10px;
  height: 50%;
}

.page-footer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.data-row-container {
  height: fit-content;
}

.send-btn {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  /* background-color: #1a3f77; */
}

.page-header .create-paid-ads {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #1a3f77;
}
/* select */
.custom-select {
  width: 90%;
  height: fit-content;
  padding: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 100%;
  appearance: none;
  border-width: 2px;
  border-color: transparent;
  box-sizing: border-box;
  padding-right: 10px;
  height: 2rem;
  flex-grow: 1;
}
select:focus {
  outline: none;
  border-color: none;
}
label {
  position: relative;
  width: 100%;
  /* min-width: 10em;
  max-width: 18em; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: rgba(255, 128, 0, 0.5);
}
label::after {
  /* content: '\25BE'; */
  content: url("../../../assets/svg/expand_more_black_icon.svg");
  background-color: var(--focused-border-color);
  color: #fff;
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 290px;
  padding-right: 25px;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.custom-text-input {
  width: 90%;
  padding: 0 10px;
  height: 54px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-text-input:focus {
  outline: none;
}

::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

/* select */
/* upload File */
.file-wrapper {
  text-align: center;
  width: 95%;
  vertical-align: middle;
  /* display: table-cell; */
  position: relative;
  overflow: hidden;
  background: #fff; /* and other things to make it pretty */
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 165px;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.file-wrapper input {
  position: absolute;
  top: 0;
  right: 0; /* not left, because only the right part of the input seems to
                 be clickable in some browser I can't remember */
  cursor: pointer;
  opacity: 0;
  filter: alpha(
    opacity=0
  ); /* and all the other old opacity stuff you
                                 want to support */
  font-size: 300px; /* wtf, but apparently the most reliable way to make
                         a large part of the input clickable in most browsers */
  height: 200px;
}

/* upload File */

.files-info-container {
  margin-top: 10px;
  list-style: none;
}

.files-container {
  height: 180px;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #dc3545;
  color: #fff;
  font-size: 10px;
}
</style>
