<template>
  <div>
    اعرض من {{ data.from }} الي {{ data.to }} من
    {{ data.total }} نتيجه بحث
  </div>
</template>

<script>
export default {
  name: "pagination-info-component",
  props:["data"],
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
</style>
