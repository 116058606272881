<template>
<Header />
  <div class="row Wrapper m-0">
    <Sidebar />
     <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <router-view/>
    </main>
  </div>

</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';


export default {
  name: 'WrapperView',
  components: {
    Sidebar,
    Header
}
}
</script>
