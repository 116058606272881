<template>
  <loadingComponent v-if="this.isLoading" />
  <router-view />
</template>


<script>
  import loadingComponent from '@/components/globalComponents/loadingComponent.vue';
  import { mapGetters } from 'vuex'

export default{
  components: {loadingComponent},
  data() {
    return{

    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
    })
  }
//   computed: {
//   confirmFlag() {
//     return this.$store.state.global.confirmFlag;
//   },
//   alertFlag() {
//     return this.$store.state.global.alertFlag;
//   },

//   isLoading(){
//       return this.$store.state.global.isLoading;
//     },
//     alertObj(){
//       return this.$store.state.global.alertObject;
//     },

// }
}
</script>



<style>
:root {
  --appMainColor: #20509a;
  --appBgColor: #f6f7fc;
}
* {
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--appBgColor);
}

#app {
  direction: rtl;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  font-size: 14px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.pagination {
  justify-content: flex-start !important;
  direction: ltr !important;
}

.pagination  > li > a{
  color: #000 !important
}

.pagination  > li > a:focus{
  box-shadow: 0 0 0 0 !important;
}

.page-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
}

.page-body {
  padding-top: 8px;
}

</style>
